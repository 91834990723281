import { axios } from ".";

const attributesAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/toolattributes`,
});

function getToolAttributesListAPICall() {
	return attributesAxios({ method: "get", url: "/attributes/get" });
}

function getToolAttributeAPICall(id) {
	return attributesAxios({ method: "get", url: `/get/${id}` });
}

function upsertToolAttributeAPICall(data) {
	return attributesAxios({
		method: "post",
		url: `/upserttoolattributes`,
		data,
	});
}

function upsertAttributeAPICall(data) {
	return attributesAxios({
		method: "post",
		url: `/upsertattribute`,
		data,
	});
}

export {
	attributesAxios,
	getToolAttributesListAPICall,
	upsertToolAttributeAPICall,
	upsertAttributeAPICall,
	getToolAttributeAPICall,
};
