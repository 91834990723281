const defaultWorkScopeInput = {
	id: null,
	workscopedescription: "",
	engine: "",
	enginemodel: "",
	technicalmanualreference: "",
	workpackagedescription: "",
	partnumber: "",
	toolnomenclature: "",
	quantity: "",
	wiptool: "",
	usewith: "",
	toolcode: "",
	qualifiedrevision: "",
	ceukca: "",
	toolsfunction: "",
	line: "",
	module: "",
	shop: "",
	repair: "",
	lmtother: "",
	duplicate: "",
	tpmcomments: "",
	dimensions: "",
	weight: "",
	loadtest: "",
	calibration: "",
	wsfilename: "",
};

const state = {
	workpackage: {
		input: defaultWorkScopeInput,
		removeFileMappingAsAdminAreYouSureModalVisible: false,
		list: [],
	},
};

export default state;
