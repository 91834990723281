import { useEffect } from "react";
import Form from "../../containers/Form";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useIsFocused } from "@react-navigation/native";
import styled from "styled-components/native";
import { vhToDp, vwToDp } from "../../../utilities/responsive";
import Page from "../../containers/Page";
import Button from "../../presentational/Button";
import EditMasterToolEngineModal from "../../containers/Modal/EditMasterToolEngineModal";
import ToolAttributeForm from "../../containers/Form/ToolAttributeForm";
import Card from "../../presentational/Card";

const Container = styled.View`
	padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function EditToolAttributePage({ navigation, ...props }) {
	const { upsertToolAttributeThunk } = useStoreActions((actions) => actions);

	return (
		<Page.Protected>
			<Container>
				<Card>
					<ToolAttributeForm
						buttons={[
							<Button
								fullWidth
								onPress={async () => {
									await upsertToolAttributeThunk({ navigation });
									navigation.goBack();
								}}
							>
								Save Changes
							</Button>,
						]}
						{...props}
					/>
				</Card>
			</Container>
		</Page.Protected>
	);
}

export default EditToolAttributePage;
