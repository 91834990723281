import { thunk } from "easy-peasy";
import {
	getWorkScopeListAPICall,
	getWorkScopeMasterToolListAPICall,
	getWorkScopeSelectionOptionsApiCall,
	getWorkScopeToolListAPICall,
	uploadWorkScopeFileApiCall,
	upsertWorkScopeAPICall,
	upsertWorkScopeFileAPICall,
	upsertWorkScopeToolAPICall,
} from "../../api/workscope";
import { connectToSignalRHub } from "../../utilities/azure";
import { uploadMasterToolFileToAzureBlobStorage } from "../../api/file";
import { DefaultError, ApiError } from "@cfbs/js-utility";
import { exportWorkScopeToolListToCsvFile } from "../../utilities/file";

const thunks = {
	getWorkScopeThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeListAPICall({
				workscopeid: 0,
				user: helpers.getState().user.authenticatedUser.email,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	upsertWorkScopeToolThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await upsertWorkScopeToolAPICall({
				id: null,
				workscopeid: 0,
			});

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	getWorkScopeToolsThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeToolListAPICall({
				workscopeid: 0,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getWorkScopeMasterToolsThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await get({
				workscopeid: 0,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	upsertWorkScopeThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await upsertWorkScopeAPICall({
				id: null,
				description: "THIS IS A TEST",
			});

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),
	upsertWorkScopeFileThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await upsertWorkScopeFileAPICall(helpers.getState().workscope.input);

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),
	uploadWorkScopeFileThunk: thunk(async (actions, file, helpers) => {
		actions.setShowUploadWorkScopeFileModalAction(false);
		actions.setIsUploadingFileAction(true);

		const connection = await connectToSignalRHub(
			process.env.REACT_APP_BACKEND_BASE_URL,
			helpers.getState().user.authenticatedUser.email,
			{
				onreconnecting: (error) =>
					actions.setIsReconnectingToAzureSignalRAction(true),
				onreconnected: (connectionId) =>
					actions.setIsReconnectingToAzureSignalRAction(false),
			}
		);

		try {
			const sheetName = "workscope";
			const signalRMethodName = "workScopeFileUpload";

			const response = await uploadMasterToolFileToAzureBlobStorage({
				base64: file.base64,
				filename: file.name,
				mimeType:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				sheetName,
			});
			actions.setTotalToolsToImportAction(response.data);

			connection.on(signalRMethodName, (processedTool) => {
				actions.setTotalToolsImportedAction(processedTool.totalToolsImported);
				actions.setTotalToolsNotImportedAction(
					processedTool.totalToolsNotImported
				);
			});

			let currentRowParsingIndex = 1;
			let hasFinishedUploading = false;
			let totalToolsImported = 0;
			let totalToolsFailed = 0;

			while (hasFinishedUploading === false) {
				const uploadProgress = await uploadWorkScopeFileApiCall({
					mimeType:
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					name: file.name,
					signalRMethodName,
					currentRowParsingIndex,
					numToolsPerBatch: 100,
					sheetName,
					totalToolsImported,
					totalToolsFailed,
					totalTools: response.data,
				});
				currentRowParsingIndex = uploadProgress.currentRowParsingIndex;
				hasFinishedUploading = uploadProgress.hasFinishedUploading;
				totalToolsImported = uploadProgress.totalToolsImported;
				totalToolsFailed = uploadProgress.totalToolsNotImported;
			}

			actions.setAlertThunk({
				type: "success",
				message: "File uploaded successfully",
			});
		} catch (err) {
			console.error(err);

			let error = new DefaultError(DefaultError.message(err));

			if (ApiError.isApiError(err))
				error = new ApiError(ApiError.default(err), err.response.status);

			actions.setAlertThunk({
				type: "error",
				message:
					error.message ||
					"An error occurred while uploading master tools from csv file",
			});

			actions.setShowUploadWorkScopeFileModalAction(true);
		}

		actions.setIsUploadingFileAction(false);
		connection && connection.stop();
	}),
	getWorkScopeMasterToolListThunk: thunk(
		async (actions, pageNumber, helpers) => {
			actions.setLoadingAction(true);

			try {
				const {
					currWorkScopeMasterToolListPageNumber,
					workScopeMasterToolListSearchInput,
					engineInput,
					engineModelInput,
					workScopeNameInput,
				} = helpers.getState().workscope;

				const list = await getWorkScopeMasterToolListAPICall({
					PageNumber: pageNumber || currWorkScopeMasterToolListPageNumber,
					PageSize: 9,
					searchpartnumber: workScopeMasterToolListSearchInput,
					wsname: workScopeNameInput,
					wsengine: engineInput,
					wsenginemodel: engineModelInput,
				});

				actions.setWorkScopeListAction(list);
			} catch (err) {
				console.error(err);

				let error = new DefaultError(DefaultError.message(err));

				if (ApiError.isApiError(err))
					error = new ApiError(ApiError.default(err), err.response.status);

				actions.setAlertThunk({
					type: "error",
					message:
						error.message ||
						"An error occurred while uploading master tools from csv file",
				});
			}

			actions.setLoadingAction(false);
		}
	),
	getWorkScopeSelectionOptionsThunk: thunk(async (actions) => {
		actions.setLoadingAction(true);

		try {
			const options = await getWorkScopeSelectionOptionsApiCall();
			actions.setWorkScopeSelectionOptionsAction(options);
		} catch (err) {
			console.error(err);

			let error = new DefaultError(DefaultError.message(err));

			if (ApiError.isApiError(err))
				error = new ApiError(ApiError.default(err), err.response.status);

			actions.setAlertThunk({
				type: "error",
				message:
					error.message ||
					"An error occurred while uploading master tools from csv file",
			});
		}

		actions.setLoadingAction(false);
	}),
	exportWorkScopeToolListCsvThunk: thunk(async (actions, payload, helpers) => {
		actions.setLoadingAction(true);

		const { engineInput, engineModelInput, workScopeNameInput } =
			helpers.getState().workscope;

		try {
			const list = await getWorkScopeMasterToolListAPICall({
				PageNumber: null,
				PageSize: null,
				wsname: workScopeNameInput,
				wsengine: engineInput,
				wsenginemodel: engineModelInput,
			});

			exportWorkScopeToolListToCsvFile(
				list,
				`WORK_SCOPE_${engineInput}_${engineModelInput}_${workScopeNameInput}`
			);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),
};

export default thunks;
