import { axios } from ".";
import { convertJsonToQueryString } from "../utilities/general";

const toolAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/tool`,
});

function getToolsAPICall(queryString) {
	return toolAxios({
		method: "get",
		url: `/list?${convertJsonToQueryString(queryString)}`,
	});
}

function getToolListDeltasAPICall(queryString) {
	return toolAxios({
		method: "get",
		url: `/list/deltas?${convertJsonToQueryString(queryString)}`,
	});
}

function getMissingToolsAPICall(queryString) {
	return toolAxios({
		method: "get",
		url: `/list/missing?${convertJsonToQueryString(queryString)}`,
	});
}

function getClientConfigurationToolListAPICall(data) {
	return toolAxios({
		method: "post",
		url: "/list/clientconfiguration",
		data,
	});
}

function getMasterToolListAPICall(data) {
	return toolAxios({
		method: "post",
		url: "/list/master",
		data,
	});
}

function getExceptionToolListAPICall(queryString) {
	return toolAxios({
		method: "get",
		url: `/list/exception?${convertJsonToQueryString(queryString)}`,
	});
}

function upsertMasterToolAPICall(data) {
	return toolAxios({
		method: "put",
		url: `/master/upsert`,
		data,
	});
}

function removeToolFromDeltaListAPICall(data) {
	return toolAxios({
		method: "delete",
		url: `/delta/delete?${convertJsonToQueryString(data)}`,
		data,
	});
}

function mergeMasterToolAPICall(data) {
	return toolAxios({
		method: "put",
		url: `/master/merge`,
		data,
	});
}

function deleteToolStatusAPICall(queryString) {
	return toolAxios({
		method: "delete",
		url: `/status?${convertJsonToQueryString(queryString)}`,
	});
}

function getToolStatusListAPICall(queryString) {
	return toolAxios({
		method: "get",
		url: `/status/list/get`,
	});
}

function upsertToolStatusAPICall(data) {
	return toolAxios({
		method: "put",
		url: `/status/upsert`,
		data,
	});
}

function revertToolChangeAPICall(data) {
	return toolAxios({
		method: "put",
		url: `'/revert/change`,
		data,
	});
}

function getToolsBackupRecord(queryString) {
	return toolAxios({
		method: "get",
		url: `/backup/record?${convertJsonToQueryString(queryString)}`,
	});
}

function revertAllToolsForOEMEngineFamilyAPICall(data) {
	return toolAxios({
		method: "put",
		url: `/revert/all`,
		data,
	});
}

function archiveToolAPICall(data) {
	return toolAxios({
		method: "delete",
		url: `/master/archive`,
		data,
	});
}

function mergeAllDeltasToMasterAPICall(data) {
	return toolAxios({
		method: "put",
		url: `/merge/deltatomaster`,
		data,
	});
}

function removeEngineFromMasterToolAPICall(queryJson) {
	return toolAxios({
		method: "delete",
		url: `/master/engine?${convertJsonToQueryString(queryJson)}`,
	});
}

function getTenantInfoByPartNumberAPICall(data) {
	return toolAxios({
		method: "get",
		url: `/partnumber/tenantinfo/${data}`,
	});
}

export {
	toolAxios,
	getToolsAPICall,
	getToolListDeltasAPICall,
	getMissingToolsAPICall,
	getClientConfigurationToolListAPICall,
	getMasterToolListAPICall,
	getExceptionToolListAPICall,
	upsertMasterToolAPICall,
	removeToolFromDeltaListAPICall,
	mergeMasterToolAPICall,
	getToolStatusListAPICall,
	upsertToolStatusAPICall,
	revertToolChangeAPICall,
	getToolsBackupRecord,
	revertAllToolsForOEMEngineFamilyAPICall,
	archiveToolAPICall,
	mergeAllDeltasToMasterAPICall,
	removeEngineFromMasterToolAPICall,
	getTenantInfoByPartNumberAPICall,
	deleteToolStatusAPICall,
};
