import { thunk } from "easy-peasy";
import { v4 } from "uuid";

const thunks = {
	setAlertThunk: thunk((actions, alert) => {
		const alertId = v4();
		actions.addAlertAction({
			...alert,
			id: alertId,
		});
		setTimeout(() => actions.removeAlertAction(alertId), 5000);
	}),
};

export default thunks;
