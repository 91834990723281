import { createStore } from "easy-peasy";
import statusStore from "./status";
import authStore from "./auth";
import userStore from "./user";
import leapStore from "./leap";
import roleStore from "./role";
import toolStore from "./tool";
import fileStore from "./file";
import integrationStore from "./integration";
import azureStore from "./azure";
import workscopeStore from "./workscope";
import attributesStore from "./attributes";
import workPackageStore from "./workpackage";
import componentStore from "./components";

const store = createStore({
	...statusStore,
	...authStore,
	...userStore,
	...leapStore,
	...roleStore,
	...toolStore,
	...fileStore,
	...integrationStore,
	...workscopeStore,
	...azureStore,
	...attributesStore,
	...workPackageStore,
	...componentStore,
});

export default store;
