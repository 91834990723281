import { action } from "easy-peasy";

const defaultUpsertPayload = {
	id: null,
	oemid: null,
	EngineFamilyID: null,
	EngineVariantID: null,
	EngineComponentName: "",
	EngineComponentDesc: "",
};

const actions = {
	setEngineComponentsListAction: action((state, payload) => {
		state.components.list = payload;
	}),
};

export default actions;
