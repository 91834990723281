import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";
import OEMListDropdown from "../../Dropdown/OEMListDropdown";
import EngineFamilyDropdown from "../../Dropdown/EngineFamilyDropdown";

function AttributeForm({ buttons = [], ...props }) {
	const { attributeInput } = useStoreState((state) => state.attributes);

	const { setAttributeInputAction } = useStoreActions((actions) => actions);

	return (
		<Form {...props}>
			<Row>
				<Col>
					<Input
						label="Name"
						onChangeText={(name) => setAttributeInputAction({ name })}
						value={attributeInput.name}
						required
					/>
				</Col>
			</Row>

			<Col>
				<Row>
					{buttons.map((button, index) => (
						<Col xs={12} md={12 / buttons.length} key={index}>
							{button}
						</Col>
					))}
				</Row>
			</Col>
		</Form>
	);
}

export default AttributeForm;
