import { action } from "easy-peasy";

const actions = {
	setAttributesListAction: action((state, list) => {
		state.attributes.attributesList = list;
	}),
	setToolAttributesAction: action((state, list) => {
		state.attributes.toolAttributes = list;
	}),

	setAttributeInputAction: action((state, input) => {
		state.attributes.attributeInput = {
			...state.attributes.attributeInput,
			...input,
		};
	}),

	setToolAttributeInputAction: action((state, input) => {
		state.attributes.toolAttributeInput = {
			...state.attributes.toolAttributeInput,
			...input,
		};
	}),

	resetAttributeInputAction: action((state) => {
		state.attributes.attributeInput = {
			id: "",
			name: "",
			datatype: "string",
		};
	}),

	resetToolAttributeInputAction: action((state) => {
		state.attributes.toolAttributeInput = {
			id: "",
			toolid: "",
			toolattributeid: "",
			value: "",
		};
	}),

	setAttributeSearchInputAction: action((state, input) => {
		state.attributes.attributeSearchInput = input;
	}),

	setIsComparingColumnFlagsAttributeAction: action((state, flags) => {
		state.attribute.isComparingColumnFlagsAttribute = flags;
	}),
	setChosenColumnToSortIndexAttributeAction: action((state, index) => {
		state.attribute.chosenColumnToSortIndexAttribute = index;
	}),

	setFilterAttributeValuesAction: action((state, list) => {
		state.attribute.filterAttributeValues = list;
	}),

	setChosenAttributeComparisonIndexesAction: action((state, list) => {
		state.attribute.chosenAttributeComparisonIndexes = list;
	}),

	setIsComparingColumnFlagsToolAttributeAction: action((state, flags) => {
		state.attribute.isComparingColumnFlagsToolAttribute = flags;
	}),
	setChosenColumnToSortIndexToolAttributeAction: action((state, index) => {
		state.attribute.chosenColumnToSortIndexToolAttribute = index;
	}),

	setFilterToolAttributeValuesAction: action((state, list) => {
		state.attribute.filterToolAttributeValues = list;
	}),

	setChosenToolAttributeComparisonIndexesAction: action((state, list) => {
		state.attribute.chosenToolAttributeComparisonIndexes = list;
	}),
};

export default actions;
