import React, { useState } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	StyleSheet,
	Animated,
	Platform,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Form from "../containers/Form";
import Row from "../presentational/Row";
import Col from "../presentational/Col";
import Input from "../presentational/Input";
import Switch from "../presentational/Switch";
import styled from "styled-components/native";
import Button from "../presentational/Button";
import Gap from "../presentational/Gap";

const HARDCODED_DATA = [
	{
		id: "leap1a",
		name: "LEAP 1A",
		children: [
			{
				id: "mm00",
				name: "MM00 - Engine Assembly",
				children: [
					{
						id: "leap1a710c",
						name: "LEAP-1A-72-00-01-S4A-710C-C",
						children: [
							{ id: "71bac_33", name: "LEAP-1A-72-24-00-33A-71BA-C" },
							{ id: "71bac_32", name: "LEAP-1A-72-24-00-32A-71BA-C" },
							{ id: "363ac_32", name: "LEAP-1A-72-24-00-32A-363A-C" },
							{ id: "71aac_44", name: "LEAP-1A-72-24-00-44A-71AA-C" },
							{ id: "363ac_44", name: "LEAP-1A-72-24-00-44A-363A-C" },
							{ id: "71aac_49", name: "LEAP-1A-72-24-00-49A-71AA-C" },
							{ id: "71aac_37", name: "LEAP-1A-72-24-00-37A-71AA-C" },
						],
					},
				],
			},
		],
	},
	{
		id: "leap1b",
		name: "LEAP 1B",
		children: [
			{
				id: "mm001",
				name: "MM01 - Engine Assembly B",
				children: [
					{
						id: "leap1b_custom",
						name: "LEAP-1B-82-00-02-S5B-810B-D",
						children: [
							{ id: "81bac_40", name: "LEAP-1B-82-34-00-40A-81BA-D" },
							{ id: "72bac_35", name: "LEAP-1B-82-34-00-35A-72BA-D" },
							{ id: "473ac_30", name: "LEAP-1B-82-34-00-30A-473A-D" },
							{ id: "72aac_50", name: "LEAP-1B-82-34-00-50A-72AA-D" },
						],
					},
				],
			},
			{
				id: "mm01",
				name: "MM01 - Turbine Module B",
				children: [
					{
						id: "leap1b_turbine",
						name: "LEAP-1B-72-10-00-T4B-900B-D",
						children: [
							{ id: "85tac_20", name: "LEAP-1B-72-34-00-20A-85TA-D" },
							{ id: "44pac_18", name: "LEAP-1B-72-34-00-18A-44PA-D" },
						],
					},
				],
			},
		],
	},
	{
		id: "leap1c",
		name: "LEAP 1C",
		children: [
			{
				id: "mm02",
				name: "MM02 - Engine Assembly C",
				children: [
					{
						id: "leap1c_custom",
						name: "LEAP-1C-92-00-03-S6C-910C-E",
						children: [
							{ id: "92bac_55", name: "LEAP-1C-92-44-00-55A-92BA-E" },
							{ id: "63cac_42", name: "LEAP-1C-92-44-00-42A-63CA-E" },
							{ id: "71dac_49", name: "LEAP-1C-92-44-00-49A-71DA-E" },
						],
					},
				],
			},
			{
				id: "mm01",
				name: "MM01 - Core Engine Module C",
				children: [
					{
						id: "leap1c_core",
						name: "LEAP-1C-72-15-00-C4C-980C-E",
						children: [
							{ id: "73fac_21", name: "LEAP-1C-72-34-00-21A-73FA-E" },
							{ id: "62pac_29", name: "LEAP-1C-72-34-00-29A-62PA-E" },
						],
					},
				],
			},
		],
	},
];

const Label = styled(Text)`
	color: #00205b;
`;

const TreeItemComponent = ({
	item,
	level,
	selectedId,
	setSelectedId,
	expandedIds,
	toggleExpanded,
}) => {
	const hasChildren = item.children && item.children.length > 0;
	const isExpanded = expandedIds.has(item.id);
	const isSelected = selectedId === item.id;

	const fadeAnim = new Animated.Value(0);

	React.useEffect(() => {
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 300,
			useNativeDriver: true,
		}).start();
	}, []);

	return (
		<Animated.View style={{ opacity: fadeAnim }}>
			<TouchableOpacity
				style={[
					styles.treeItem,
					{ marginLeft: level * 20 },
					isSelected && styles.selectedItem,
				]}
				onPress={() => setSelectedId(item.id)}
			>
				{hasChildren && (
					<TouchableOpacity
						style={styles.expandButton}
						onPress={() => toggleExpanded(item.id)}
					>
						<Text style={styles.expandIcon}>{isExpanded ? "-" : "+"}</Text>
					</TouchableOpacity>
				)}
				<Text style={[styles.itemText, isSelected && styles.selectedText]}>
					{item.name}
				</Text>
			</TouchableOpacity>

			{isExpanded && hasChildren && (
				<View>
					{item.children.map((child) => (
						<TreeItemComponent
							key={child.id}
							item={child}
							level={level + 1}
							selectedId={selectedId}
							setSelectedId={setSelectedId}
							expandedIds={expandedIds}
							toggleExpanded={toggleExpanded}
						/>
					))}
				</View>
			)}
		</Animated.View>
	);
};

const ContentView = ({ selectedItem }) => {
	const [tools, setTools] = useState([
		"DOLLY, ASSEMBLY/DISSASSEMBLY - IGB (956A8110G02)",
	]);

	return (
		<View style={styles.contentContainer}>
			{selectedItem ? (
				<Form>
					<Row>
						<Col xs={12} md={10}>
							<Input
								label="Name"
								onChangeText={(name) => setAttributeInputAction({ name })}
								value={""}
								required
							/>
						</Col>
						<Col xs={12} md={2}>
							<Button fullWidth>Save</Button>
						</Col>
					</Row>

					<Row>
						<Col>
							<Input
								label="Description"
								onChangeText={(name) => setAttributeInputAction({ name })}
								value={""}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={12} md={5}>
							<Input
								label="Parent"
								onChangeText={(name) => setAttributeInputAction({ name })}
								value={""}
								required
							/>
						</Col>
						<Col xs={12} md={5}>
							<Input
								label="Order"
								onChangeText={(name) => setAttributeInputAction({ name })}
								value={""}
								required
							/>
						</Col>
						<Col xs={12} md={2}>
							<Label>Special Tools</Label>
							<Gap />
							<Switch
								onValueChange={(ismilitary) =>
									setUpsertEngineVariantInputAction({ ismilitary })
								}
								value={false}
								disabled={false}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<View style={styles.toolsHeader}>
								<Label>Special Tools</Label>

								<TouchableOpacity style={styles.addButton}>
									<Text style={styles.addButtonText}>Add</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.toolsList}>
								{tools.map((tool, index) => (
									<View key={index} style={styles.toolItem}>
										<Text>{tool}</Text>
									</View>
								))}
							</View>
						</Col>
					</Row>

					<Row>
						<Col>
							<Label>Notes</Label>
							<Input multiline numberOfLines={4} />
						</Col>
					</Row>
				</Form>
			) : (
				<Text style={styles.noContentText}>Select an item to view details</Text>
			)}
		</View>
	);
};

const TreeView = () => {
	const [selectedId, setSelectedId] = useState(null);
	const [expandedIds, setExpandedIds] = useState(new Set());

	const toggleExpanded = (id) => {
		setExpandedIds((prev) => {
			const next = new Set(prev);
			if (next.has(id)) {
				next.delete(id);
			} else {
				next.add(id);
			}
			return next;
		});
	};

	const findSelectedItem = (items, id) => {
		for (const item of items) {
			if (item.id === id) return item;
			if (item.children) {
				const found = findSelectedItem(item.children, id);
				if (found) return found;
			}
		}
		return null;
	};

	return (
		<SafeAreaView style={styles.container}>
			<View style={styles.mainContent}>
				<View style={styles.treeContainer}>
					<ScrollView>
						{HARDCODED_DATA.map((item) => (
							<TreeItemComponent
								key={item.id}
								item={item}
								level={0}
								selectedId={selectedId}
								setSelectedId={setSelectedId}
								expandedIds={expandedIds}
								toggleExpanded={toggleExpanded}
							/>
						))}
					</ScrollView>
				</View>
				<View style={styles.contentView}>
					<ContentView
						selectedItem={findSelectedItem(HARDCODED_DATA, selectedId)}
					/>
				</View>
			</View>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#f5f5f5",
	},
	mainContent: {
		flex: 1,
		flexDirection: "row",
	},
	treeContainer: {
		flex: 1,
		backgroundColor: "#fff",
		borderRightWidth: 1,
		borderRightColor: "#e0e0e0",
		maxWidth: 400,
	},
	contentView: {
		flex: 2,
		backgroundColor: "#fff",
	},
	treeItem: {
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: "#f0f0f0",
	},
	selectedItem: {
		backgroundColor: "#e3f2fd",
	},
	expandButton: {
		padding: 4,
		marginRight: 8,
		width: 20,
		height: 20,
		alignItems: "center",
		justifyContent: "center",
	},
	expandIcon: {
		fontSize: 18,
		fontWeight: "bold",
		color: "#666",
		lineHeight: 20,
	},
	itemText: {
		fontSize: 14,
		color: "#333",
		flex: 1,
	},
	selectedText: {
		color: "#1976d2",
		fontWeight: "500",
	},
	contentContainer: {
		padding: 20,
	},
	contentTitle: {
		fontSize: 18,
		fontWeight: "bold",
		marginBottom: 10,
	},
	noContentText: {
		fontSize: 16,
		color: "#666",
		textAlign: "center",
	},

	container: {
		flex: 1,
		backgroundColor: "#f5f5f5",
	},
	header: {
		padding: 16,
		backgroundColor: "#fff",
		borderBottomWidth: 1,
		borderBottomColor: "#e0e0e0",
		...Platform.select({
			ios: {
				shadowColor: "#000",
				shadowOffset: { width: 0, height: 2 },
				shadowOpacity: 0.1,
				shadowRadius: 2,
			},
			android: {
				elevation: 2,
			},
		}),
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		color: "#333",
	},
	mainContent: {
		flex: 1,
		flexDirection: "row",
	},
	treeContainer: {
		flex: 1,
		backgroundColor: "#fff",
		borderRightWidth: 1,
		borderRightColor: "#e0e0e0",
		maxWidth: 500,
	},
	contentView: {
		flex: 2,
		backgroundColor: "#fff",
	},
	treeItem: {
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
		borderBottomWidth: 1,
		borderBottomColor: "#f0f0f0",
	},
	selectedItem: {
		backgroundColor: "#e3f2fd",
	},
	expandButton: {
		padding: 4,
		marginRight: 8,
	},
	itemText: {
		fontSize: 15,
		color: "#333",
	},
	selectedText: {
		color: "#1976d2",
		fontWeight: "500",
	},
	contentContainer: {
		padding: 20,
	},
	noContentText: {
		fontSize: 16,
		color: "#666",
		textAlign: "center",
	},

	formHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20,
	},
	saveButton: {
		backgroundColor: "#e0e0e0",
		padding: 8,
		borderRadius: 4,
	},
	label: {
		fontSize: 14,
		fontWeight: "500",
		marginBottom: 4,
	},
	input: {
		borderWidth: 1,
		borderColor: "#e0e0e0",
		borderRadius: 4,
		padding: 8,
	},
	descriptionInput: {
		height: 80,
	},
	selectContainer: {
		position: "relative",
	},
	dropdownArrow: {
		position: "absolute",
		right: 8,
		top: "50%",
		marginTop: -10,
	},
	numberInput: {
		flexDirection: "row",
	},
	numberControls: {
		marginLeft: -1,
	},
	numberControl: {
		borderWidth: 1,
		borderColor: "#e0e0e0",
		padding: 4,
	},
	toolsHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	addButton: {
		backgroundColor: "#1976d2",
		padding: 8,
		borderRadius: 4,
	},
	addButtonText: {
		color: "white",
	},
	toolsList: {
		borderWidth: 1,
		borderColor: "#e0e0e0",
		borderRadius: 4,
		padding: 8,

		height: 100,
	},
	toolItem: {
		padding: 8,
		borderWidth: 0.01,
		borderColor: "#e0e0e0",
	},
	notesInput: {
		height: 100,
	},
	specialTools: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
});

export default TreeView;
