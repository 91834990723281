import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import PaginatedList from "../../presentational/PaginatedList";

function ToolAttributeList(props) {
	const {
		attributesList,
		chosenToolAttributeComparisonIndexes,
		filterToolAttributeValues,
		chosenColumnToSortIndexToolAttribute,
		isComparingColumnFlagsToolAttribute,
		toolAttributes,
	} = useStoreState((state) => state.attributes);

	const {
		setIsComparingColumnFlagsToolAttributeAction,
		setChosenColumnToSortIndexToolAttributeAction,
		setFilterToolAttributeValuesAction,
		setChosenToolAttributeComparisonIndexesAction,
		setToolAttributeInputAction,
	} = useStoreActions((actions) => actions);

	const navigation = useNavigation();

	return (
		<PaginatedList
			chosenComparisonIndexes={chosenToolAttributeComparisonIndexes}
			setChosenComparisonIndexes={setChosenToolAttributeComparisonIndexesAction}
			filterValues={filterToolAttributeValues}
			setFilterValues={setFilterToolAttributeValuesAction}
			setChosenColumnToSortIndex={setChosenColumnToSortIndexToolAttributeAction}
			chosenColumnToSortIndex={chosenColumnToSortIndexToolAttribute}
			isComparingColumnFlags={isComparingColumnFlagsToolAttribute}
			setIsComparingColumnFlags={setIsComparingColumnFlagsToolAttributeAction}
			tableHeaders={["Name", "Description", "Edit"]}
			headers={[
				{
					columnName: "Name",
					propertyName: "name",
				},
				{
					columnName: "Value",
					propertyName: "value",
				},
				{
					columnName: "Edit",
					propertyName: "value",
				},
			]}
			list={toolAttributes
				// .filter((role) =>
				// 	formatStringForComparison(role.name || "").includes(
				// 		formatStringForComparison(searchInput) || ""
				// 	)
				// )
				.map((role) => ({
					name: role.name,
					value: role.value,
					edit: (
						<Icon
							Component={FontAwesome5}
							name="edit"
							onPress={() => {
								navigation.navigate("Edit Tool Attribute");

								setToolAttributeInputAction(role);
							}}
						/>
					),
				}))}
			{...props}
		/>
	);
}

export default ToolAttributeList;
