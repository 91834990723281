import { useStoreActions, useStoreState } from "easy-peasy";
import { formatStringForComparison } from "../utilities/string";
import { useTableFilter } from "./filter";
import { removeDuplicateChoices } from "../utilities/input";
import ViewMasterToolForm from "../components/containers/Form/Tool/ViewMasterToolForm";

function useClientConfigGroupedToolTable() {
	const {
		tool: {
			clientConfigToolHeaders,
			clientConfigGroupedList,
			clientConfigurationSearchInput,
			currentConfigGroupedPage,
			configFilter,
			requestedToolList,
			isApplyingFindToolFilter,
		},
		leap: { uploadType, uploadEngineModel },
	} = useStoreState((state) => state);

	const {
		updateClientConfigToolHeaderAction,
		getClientConfigurationToolListThunk,
		setCurrentConfigGroupedPageAction,
	} = useStoreActions((actions) => actions);

	function reloadListByPageNumber(PageNumber) {
		getClientConfigurationToolListThunk({
			OEM: uploadType,
			engineFamilyID: uploadEngineModel == "All" ? "" : uploadEngineModel,
			enginevariantid: configFilter == "All" ? "" : configFilter,
			requestedToolList: requestedToolList
				.trim()
				.split(/[\n]+/)
				.filter((item) => item)
				.join(","),
			PageNumber,
		});
		setCurrentConfigGroupedPageAction(PageNumber);
	}

	const { filteredList, ...tableProps } = useTableFilter({
		headers: clientConfigToolHeaders,
		list: clientConfigGroupedList,
		updateHeaderFunction: updateClientConfigToolHeaderAction,
		reloadListByPageNumberFunction: reloadListByPageNumber,
		currPageNumber: currentConfigGroupedPage,
		setPageNumberFunction: setCurrentConfigGroupedPageAction,
	});

	const renderExpandedContent = (item, rowIndex) => (
		<ViewMasterToolForm item={item} rowIndex={rowIndex} />
	);

	return {
		...tableProps,
		isRowExpandable: true,
		renderExpandedContent,
		isApplyingFindToolFilter,
		list: filteredList.filter((tool) =>
			formatStringForComparison(tool.partNumber).includes(
				formatStringForComparison(clientConfigurationSearchInput)
			)
		),
	};
}

function useClientConfigToolListControlForm() {
	const {
		tool: { configFilter, requestedToolList, clientConfigurationSearchInput },
		user: { accessControlList },
		leap: { uploadType, uploadEngineModel },
	} = useStoreState((state) => state);

	const {
		setToolInputAction,
		setToolConfigFilterAction,
		setRequestedToolListModalVisibleAction,
		getClientConfigurationToolListThunk,
		setUploadTypeAction,
		setUploadEngineModelAction,
		setCurrentConfigGroupedPageAction,
		setClientConfigurationSearchInputAction,
		setEnteredClientConfigurationSearchInputAction,
	} = useStoreActions((actions) => actions);

	const defaultItem = "All";

	function value(input) {
		return input || defaultItem;
	}

	function onSelect(callback) {
		return (option) => {
			callback(option);
			setCurrentConfigGroupedPageAction(1);
		};
	}

	return {
		engineModelInput: {
			label: "Engine Model",
			onSelect: onSelect((option) => {
				setUploadEngineModelAction(option.EngineFamilyID);
				setToolConfigFilterAction("");
				setUploadTypeAction("");
				getClientConfigurationToolListThunk({
					OEM: uploadType,
					engineFamilyID: option.EngineFamilyID,
					enginevariantid: configFilter,
					requestedToolList: requestedToolList
						.trim()
						.split(/[\n]+/)
						.filter((item) => item)
						.join(","),
					PageNumber: 1,
				});
			}),
			value: value(
				accessControlList.find(
					(map) => map.EngineFamilyID === uploadEngineModel
				)?.EngineFamily
			),
			dropdownChoices: [
				{ EngineFamilyID: "", EngineFamily: defaultItem },
				...removeDuplicateChoices(accessControlList, "EngineFamily"),
			],
			selectedItem: (option) => option.EngineFamily,
			rowTextForSelection: (option) => option.EngineFamily,
		},
		configInput: {
			label: "Config",
			placeholder: "Enter Config",
			onSelect: onSelect((option) => {
				setToolConfigFilterAction(option.EngineVariantID);
				setUploadTypeAction("");
				getClientConfigurationToolListThunk({
					OEM: uploadType,
					engineFamilyID: uploadEngineModel,
					enginevariantid: option.EngineVariantID,
					requestedToolList: requestedToolList
						.trim()
						.split(/[\n]+/)
						.filter((item) => item)
						.join(","),
					PageNumber: 1,
				});
			}),
			value: value(
				accessControlList.find((map) => map.EngineVariantID === configFilter)
					?.EngineVariant
			),
			dropdownChoices: [
				{ EngineVariantID: "", EngineVariant: defaultItem },
				...removeDuplicateChoices(
					accessControlList.filter(
						(option) =>
							!uploadEngineModel || option.EngineFamilyID === uploadEngineModel
					),
					"EngineVariant"
				),
			],
			selectedItem: (option) => option.EngineVariant,
			rowTextForSelection: (option) => option.EngineVariant,
		},
		oemInput: {
			label: "OEM",
			placeholder: "Enter OEM",
			onSelect: onSelect((option) => {
				setUploadTypeAction(option.OEMID);
				setToolInputAction({ OEM: option.oem });
				getClientConfigurationToolListThunk({
					OEM: option.OEMID,
					engineFamilyID: uploadEngineModel,
					enginevariantid: configFilter,
					requestedToolList: requestedToolList
						.trim()
						.split(/[\n]+/)
						.filter((item) => item)
						.join(","),
					PageNumber: 1,
				});
			}),
			value: value(
				accessControlList.find((map) => map.OEMID === uploadType)?.oem
			),
			dropdownChoices: [
				{ OEMID: "", oem: defaultItem },
				...removeDuplicateChoices(
					accessControlList.filter(
						(option) =>
							(!uploadEngineModel ||
								option.EngineFamilyID === uploadEngineModel) &&
							(!configFilter || option.EngineVariantID === configFilter)
					),
					"oem"
				),
			],
			selectedItem: (option) => option.oem,
			rowTextForSelection: (option) => option.oem,
		},
		button: {
			fullWidth: true,
			onPress: () => setRequestedToolListModalVisibleAction(true),
			children: "Find Tools",
		},
		searchBar: {
			onChangeText: (searchInput) =>
				setClientConfigurationSearchInputAction(searchInput),
			value: clientConfigurationSearchInput,
			placeholder: "Search tool by OEM, Engine, and Config",
			onSubmitEditing: () => {
				setEnteredClientConfigurationSearchInputAction(
					clientConfigurationSearchInput
				);
				setCurrentConfigGroupedPageAction(1);
				getClientConfigurationToolListThunk({
					OEM: uploadType,
					engineFamilyID: uploadEngineModel,
					enginevariantid: configFilter,
					requestedToolList: requestedToolList
						.trim()
						.split(/[\n]+/)
						.filter((item) => item)
						.join(","),
					PageNumber: 1,
					searchpartnumber: clientConfigurationSearchInput,
				});
			},
		},
		required: true,
		dropdown: true,
		editable: true,
		width: "99%",
	};
}

export { useClientConfigGroupedToolTable, useClientConfigToolListControlForm };
