import { Col, Form, Row } from "@cfbs/cfbsstrap-native";
import Input from "../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";

const ToolAttributeForm = ({ buttons = [], ...props }) => {
	const { toolAttributeInput, attributesList } = useStoreState(
		(state) => state.attributes
	);
	const { setToolAttributeInputAction } = useStoreActions((actions) => actions);

	return (
		<Form {...props}>
			<Row>
				<Col xs={12} md={6} lg={6}>
					<Input
						label="Tool Attribute"
						placeholder="Enter Tool Attribute"
						value={
							attributesList.find(
								(item) => item.id == toolAttributeInput.toolattributeid
							)?.name
						}
						editable={true}
						onSelect={(item) =>
							setToolAttributeInputAction({ toolattributeid: item.id })
						}
						required
						dropdown
						dropdownChoices={attributesList}
						selectedItem={(item) => item.name}
						rowTextForSelection={(item) => item.name}
					/>
				</Col>

				<Col xs={12} md={6} lg={6}>
					<Input
						label="Value"
						placeholder="Enter value"
						onChangeText={(value) => setToolAttributeInputAction({ value })}
						value={toolAttributeInput.value}
						editable={true}
						keyboardType="numeric"
					/>
				</Col>
			</Row>

			<Col>
				<Row>
					{buttons.map((button, index) => (
						<Col xs={12} md={12 / buttons.length} key={index}>
							{button}
						</Col>
					))}
				</Row>
			</Col>
		</Form>
	);
};

export default ToolAttributeForm;
