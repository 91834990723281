import { thunk } from "easy-peasy";
import { v4 } from "uuid";
import {
	getWorkScopeListAPICall,
	getWorkScopeToolListAPICall,
	upsertWorkScopeAPICall,
	upsertWorkScopeFileAPICall,
	upsertWorkScopeToolAPICall,
} from "../../api/workscope";

const thunks = {
	getWorkPackageThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkP({
				workscopeid: 0,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),
	getWorkPackageItemsThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeListAPICall({
				workscopeid: 0,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getWorkPackageMasterToolListThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getWorkScopeListAPICall({
				workscopeid: 0,
				user: helpers.getState().user.authenticatedUser,
			});
			actions.setWorkScopeListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	upsertWorkPackageThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			await upsertWorkScopeToolAPICall({
				id: null,
				workscopeid: 0,
			});

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	upsertWorkPackageItemThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);
		try {
			await upsertWorkScopeToolAPICall({
				id: null,
				workscopeid: 0,
			});

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	upsertWorkPackageMasterToolThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);
		try {
			await upsertWorkScopeToolAPICall({
				id: null,
				workscopeid: 0,
			});

			actions.setAlertThunk({
				type: "success",
				message: "Work Scope added successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),
};

export default thunks;
