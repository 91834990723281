import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../../presentational/Button";
import ToolStatusForm from "./AttributeForm";
import { useNavigation } from "@react-navigation/native";
import AttributeForm from "./AttributeForm";

function EditAttributeForm(props) {
	const { upsertToolStatusThunk, deleteToolStatusThunk } = useStoreActions(
		(actions) => actions
	);
	const navigation = useNavigation();
	return (
		<AttributeForm
			buttons={[
				<Button
					color="#AB2328"
					fullWidth
					onPress={async () => {
						await deleteToolStatusThunk();
						navigation.goBack();
					}}
				>
					Delete
				</Button>,

				<Button
					fullWidth
					onPress={async () => {
						await upsertToolStatusThunk();
						navigation.goBack();
					}}
				>
					Save
				</Button>,
			]}
			{...props}
		/>
	);
}

export default EditAttributeForm;
