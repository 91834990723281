import { action } from "easy-peasy";

const actions = {
	setWorkScopeInputAction: action((state, payload) => {
		state.workscope.input = {
			...state.workscope.input,
			...payload,
		};
	}),
	setRemoveFileMappingAsAdminAreYouSureModalVisibleAction: action(
		(state, payload) => {
			state.workscope.removeFileMappingAsAdminAreYouSureModalVisible = payload;
		}
	),
	setWorkScopeListAction: action((state, workScopeList) => {
		state.workscope.list = workScopeList;
	}),
};

export default actions;
