import { View } from "react-native";
import styled from "styled-components/native";
import Row from "../../presentational/Row";
import Button from "../../containers/Button";
import Col from "../../presentational/Col";
import Page from "../../containers/Page";
import SearchBar from "../../presentational/SearchBar";
import React, { useEffect } from "react";
import Gap from "../../presentational/Gap";
import { useIsFocused } from "@react-navigation/native";
import { useStoreActions, useStoreState } from "easy-peasy";

import List from "../../functional/List";
import Card from "../../presentational/Card";
import Input from "../../presentational/Input";

const Container = styled(View)`
	padding: 2vh 4vw;
`;

const SRow = styled(Row)`
	align-items: center;
`;

function AttributeListPage({ navigation, ...props }) {
	const {
		user: { authenticatedUser },
		attributes: { attributeSearchInput },
	} = useStoreState((state) => state);

	const {
		getMasterToolListThunk,
		getEngineFamilyListThunk,
		getAttributesListThunk,
		setAttributeSearchInputAction,
	} = useStoreActions((actions) => actions);

	const isFocused = useIsFocused();

	useEffect(() => {
		if (isFocused) {
			getAttributesListThunk();
		}
	}, [isFocused]);

	return (
		<Page.Protected {...props}>
			<Container>
				<SRow>
					<Col xs={12} md={4}>
						<Button fullWidth onPress={() => navigation.push("Add Attribute")}>
							Add Attribute
						</Button>
					</Col>

					<Col xs={12} sm={8}>
						<SearchBar
							placeholder="Search Attribute"
							onChangeText={setAttributeSearchInputAction}
							value={attributeSearchInput}
						/>
					</Col>
				</SRow>

				<Gap />

				<Card>
					<List.Attributes />
				</Card>

				<Gap />
			</Container>
		</Page.Protected>
	);
}

export default AttributeListPage;
