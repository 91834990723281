const defaultAttributeInput = {
	id: "",
	name: "",
	datatype: "string",
};

const defaultToolAttributeInput = {
	id: "",
	toolid: "",
	toolattributeid: "",
	value: "",
};

const state = {
	attributes: {
		attributeInput: defaultAttributeInput,
		toolAttributeInput: defaultToolAttributeInput,
		attributesList: [],
		toolAttributes: [],

		isComparingColumnFlagsAttribute: [false, false],
		chosenColumnToSortIndexAttribute: -1,
		filteredAttributeList: [],
		filterAttributeValues: ["", "", ""],
		chosenAttributeComparisonIndexes: [-1, -1, -1],

		isComparingColumnFlagsToolAttribute: [false, false],
		chosenColumnToSortIndexToolAttribute: -1,
		filteredToolAttributeList: [],
		filterToolAttributeValues: ["", "", ""],
		chosenToolAttributeComparisonIndexes: [-1, -1, -1],
		attributeSearchInput: "",
	},
};

export default state;
