import { thunk } from "easy-peasy";
import { v4 } from "uuid";
import {
	getToolAttributeAPICall,
	getToolAttributesListAPICall,
	upsertAttributeAPICall,
	upsertToolAttributeAPICall,
} from "../../api/attributes";

const thunks = {
	getAttributesListThunk: thunk(async (actions, _, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getToolAttributesListAPICall();
			console.log(response);
			actions.setAttributesListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	getToolAttributeThunk: thunk(async (actions, id, helpers) => {
		actions.setLoadingAction(true);

		try {
			const response = await getToolAttributeAPICall(id);
			console.log(response);
			actions.setToolAttributesAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),

	upsertAttributeThunk: thunk(async (actions, data, helpers) => {
		actions.setLoadingAction(true);
		try {
			const response = await upsertAttributeAPICall({
				...helpers.getState().attributes.attributeInput,
				dataType: "string",
			});
			actions.setAttributesListAction(response.data);
			actions.setAlertThunk({
				type: "success",
				message: "Attribute saved successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),

	upsertToolAttributeThunk: thunk(async (actions, data, helpers) => {
		actions.setLoadingAction(true);
		try {
			await upsertToolAttributeAPICall({
				...helpers.getState().attributes.toolAttributeInput,
				toolid: helpers.getState().tool.masterToolInput.id,
			});
			// actions.setToolAttributesAction(response.data);
			actions.getToolAttributeThunk(helpers.getState().tool.masterToolInput.id);

			actions.setToolAttributeInputAction({
				id: "",
				toolid: "",
				toolattributeid: "",
				value: "",
			});
			actions.setAlertThunk({
				type: "success",
				message: "Attribute saved successfully",
			});
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}
		actions.setLoadingAction(false);
	}),
};

export default thunks;
