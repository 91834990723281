import { axios } from ".";

const componentAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/engine-components`,
});

function getEngineComponentsListAPICall(id) {
	return componentAxios({ method: "get", url: `/${id}` });
}

function upsertEngineComponentAPICall(data) {
	return componentAxios({
		method: "put",
		url: `/engine-components`,
		data,
	});
}

export {
	componentAxios,
	getEngineComponentsListAPICall,
	upsertEngineComponentAPICall,
};
