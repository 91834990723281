import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import PaginatedList from "../../presentational/PaginatedList";

function AttributeList(props) {
	const {
		attributesList,
		attributeSearchInput,

		chosenAttributeComparisonIndexes,
		filterAttributeValues,
		chosenColumnToSortIndexAttribute,
		isComparingColumnFlagsAttribute,
	} = useStoreState((state) => state.attributes);

	const {
		setChosenAttributeComparisonIndexesAction,
		setFilterAttributeValuesAction,
		setChosenColumnToSortIndexAttributeAction,
		setIsComparingColumnFlagsAttributeAction,
		setAttributeInputAction,
	} = useStoreActions((actions) => actions);

	const navigation = useNavigation();

	return (
		<PaginatedList
			chosenComparisonIndexes={chosenAttributeComparisonIndexes}
			setChosenComparisonIndexes={setChosenAttributeComparisonIndexesAction}
			filterValues={filterAttributeValues}
			setFilterValues={setFilterAttributeValuesAction}
			setChosenColumnToSortIndex={setChosenColumnToSortIndexAttributeAction}
			chosenColumnToSortIndex={chosenColumnToSortIndexAttribute}
			isComparingColumnFlags={isComparingColumnFlagsAttribute}
			setIsComparingColumnFlags={setIsComparingColumnFlagsAttributeAction}
			tableHeaders={["Name", "Edit"]}
			headers={[
				{
					columnName: "Name",
					propertyName: "name",
				},
				{
					columnName: "Edit",
					propertyName: "name",
				},
			]}
			list={attributesList
				.filter((role) =>
					formatStringForComparison(role.name || "").includes(
						formatStringForComparison(attributeSearchInput) || ""
					)
				)
				.map((role) => ({
					name: role.name,
					edit: (
						<Icon
							Component={FontAwesome5}
							name="edit"
							onPress={() => {
								navigation.navigate("Edit Attribute");

								setAttributeInputAction(role);
							}}
						/>
					),
				}))}
			{...props}
		/>
	);
}

export default AttributeList;
