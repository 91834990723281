import { thunk } from "easy-peasy";
import { v4 } from "uuid";
import { getEngineComponentsListAPICall } from "../../api/components";

const thunks = {
	getEngineComponentsListThunk: thunk(async (actions, payload = 0) => {
		actions.setLoadingAction(true);

		try {
			const response = await getEngineComponentsListAPICall(payload);
			actions.setEngineComponentsListAction(response.data);
		} catch (err) {
			console.log(err);
			actions.setAlertThunk({
				type: "error",
				message: err.response.data,
			});
		}

		actions.setLoadingAction(false);
	}),
};

export default thunks;
